<template>
  <section class="bg-gray-200 page-genuses">
    <div
      v-if="initializing"
      class="flex flex-col items-center justify-center h-screen"
    >
      <van-loading color="#0094ff">
        加载中...
      </van-loading>
    </div>
    <template v-else>
      <template v-if="loading || genuses.total">
        <van-pull-refresh
          v-model="refreshing"
          @refresh="onRefresh"
        >
          <van-list
            v-model="loading"
            :finished="genuses.to === null || genuses.to == genuses.total"
            :finished-text="loading ? '加载中...' : '没有更多了'"
            error-text="加载失败，请下拉页面重新加载"
            :immediate-check="false"
            class="w-screen p-3"
            @load="fetch"
          >
            <router-link
              v-for="genus of genuses.data"
              :key="`genus-${genus._id}`"
              class="hover:no-underline"
              :to="{
                name: 'public.trees.genuses.view',
                params: { genus: genus._id }
              }"
            >
              <div
                class="
                  box-border
                  flex flex-row
                  items-start
                  justify-start
                  w-full
                  p-3
                  mb-3
                  text-black
                  bg-white
                  rounded-md
                  genus-item
                  text
                "
              >
                <van-image
                  width="100"
                  height="100"
                  class="rounded-image"
                  :src="genus.thumbnail"
                />
                <div class="flex-col items-start justify-start flex-1 pl-2">
                  <div class="w-full mb-0 -mt-1 overflow-hidden">
                    <van-badge
                      v-if="genus.color"
                      dot
                      :color="genus.color"
                    /><span class="ml-1 text-sm font-semibold">{{
                      genus.name
                    }}</span>
                    <span
                      v-if="counts[genus._id]"
                      class="text-xs ml-1 text-gray-300"
                    >（{{ counts[genus._id] }}棵）</span>
                  </div>
                  <div
                    class="
                      mb-1
                      leading-none
                      flex flex-row
                      justify-between
                      items-center
                    "
                  >
                    <van-tag
                      v-if="genus.floweringMonthStart"
                      color="#ccc"
                      text-color="#333"
                      class="px-2 text-xs"
                    >
                      花期：{{ genus.floweringMonthStart }}月<template
                        v-if="
                          genus.floweringMonthEnd &&
                            genus.floweringMonthEnd != genus.floweringMonthStart
                        "
                      >
                        - {{ genus.floweringMonthEnd }}月
                      </template>
                    </van-tag>
                    <div
                      v-if="counts[genus._id]"
                      class="text-xs text-yellow-400"
                      @click.prevent.stop="goMap(genus._id)"
                    >
                      <i class="fal fa-location" /> 分布图
                    </div>
                  </div>
                  <p
                    class="
                      mt-1
                      text-xs
                      leading-relaxed
                      text-gray-500
                      van-multi-ellipsis--l3
                    "
                  >
                    {{ genus.description }}
                  </p>
                </div>
              </div>
            </router-link>
          </van-list>
          <div class="button-placeholder" />
        </van-pull-refresh>
      </template>
    </template>
  </section>
</template>

<script>
import share from '@/mixins/share'
import { mapState } from 'vuex'
export default {
  name: 'PublicTreesGenuses',
  mixins: [share],
  data() {
    return {
      mode: null,
      from: null,
      loading: true,
      initializing: true,
      refreshing: false,
      genuses: {
        current_page: null,
        to: 0,
        total: 0,
        data: []
      },
      counts: {}
    }
  },
  computed: {
    ...mapState('Common/Cloud', ['tcb']),
    ...mapState('Common/Credential', ['userInfo'])
  },
  async mounted() {
    await Promise.all([this.getStatistics(), this.prepareWx()])
    await this.fetch()
    let options = {
      title: '树木种类',
      desc: '阳光丽景小区内的树木信息'
    }
    this.updateShareData(options)
  },
  methods: {
    async getStatistics() {
      try {
        const { result: {data} } = await this.tcb.callFunction({
          name: 'trees',
          data: {
            $url: 'dashboard'
          }
        })
        // eslint-disable-next-line no-console
        console.log('统计函数请求返回', data)
        // let { result } = response
        this.$set(
          this,
          'counts',
          data.reduce((acc, cur) => {
            acc[cur.genusId] = cur.num
            return acc
          }, {})
        )
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
        this.counts = {}
      }
    },
    onRefresh() {
      this.refreshing = true
      this.fetch()
    },
    async fetch() {
      if (this.refreshing) {
        // eslint-disable-next-line no-console
        console.warn('这是在刷新页面')
        this.genuses = {
          current_page: 0,
          total: 0,
          to: 0,
          data: []
        }
        this.refreshing = false
      }
      let page = (this.genuses.current_page ?? 0) + 1

      this.loading = true
      try {
        // eslint-disable-next-line no-console
        console.log('开始请求数据', page)
        const response = await this.tcb.callFunction({
          name: 'trees',
          data: {
            $url: 'genuses',
            page: page
          }
        })
        // eslint-disable-next-line no-console
        console.log('函数请求返回', response)
        let { result } = response
        // eslint-disable-next-line no-console
        console.warn('总共有数据', page, response)
        let genuses = {
          data: this.genuses.data.concat(result.data.data),
          to: this.genuses.to + result.data.data.length,
          current_page: page,
          total: result.data.total
        }
        // eslint-disable-next-line no-console
        console.log('数据加载完毕', genuses)
        this.$set(this, 'genuses', genuses)
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
        this.$notify({ type: 'danger', message: '暂时无法获取树木种类信息' })
        this.genuses = {
          current_page: 0,
          total: 0,
          to: 0,
          data: []
        }
      }
      this.loading = false
      this.initializing = false
    },
    goMap(id) {
      this.$router.push({
        name: 'public.trees.map',
        query: { genus: id }
      })
    }
  }
}
</script>

<style lang="scss">
.rounded-image {
  img {
    border-radius: 4px;
  }
}
</style>
